import Link from 'next/link';
import { 
  Box,
  Stack,
  Center,
  Card,
  CardHeader, 
  CardBody, 
  Divider, 
  Heading, 
  Icon,
  IconButton,
  Skeleton,
  Tag,
  TagLabel,
  Avatar
} from '@chakra-ui/react'
import { MdStar, MdPeople, MdPlace } from 'react-icons/md'
import { FaHashtag } from 'react-icons/fa';
import clsx from 'clsx';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Waypoint } from 'react-waypoint';
import { useGlobal, useUser } from 'src/context';
import { featureCodeMap, allCityCodeMap, GAME_CARD_IMAGE_PLACEHOLDER_SRC, trackEvent, getGameTypeDisplay } from 'utils';

export const SkeletonCard = () => {
  return (
    <Box w={{ base: '168px', sm:'300px' }} boxShadow='md' bg='white'>
      <Skeleton height={{ base: '88px', sm: '157px' }} width="100%" />
      <Stack p={2}>
        <Skeleton height='1.2rem' width="60%" mb={{ base: 2, sm: 3 }} />
        <Skeleton height={{ base: '0.75rem', sm: '1rem' }} width="80%" />
        <Skeleton height={{ base: '0.75rem', sm: '1rem' }} width="50%" my={{ base: 1, sm: 2 }} />
        <Skeleton height={{ base: '0.75rem', sm: '1rem' }} width="70%" />
      </Stack>
    </Box>
  )
};


export const FullGameCard = (props) => {
  const {
    gameData,
    sourcePage = "",
  } = props;

  const { 
    gameId, 
    title, 
    firmLogoUrl,
    firmsList,
    mainImgSquareUrl, 
    mainImgBannerUrl,
    isPromotion,
    isOutdoorGame,
    isScenarioGame,
    isTheaterGame,
    isBigGame,
    isGameOver,
    features,
    cityId,
    minPeople,
    maxPeople,
    minPrice,
    avgRating,
    totalRatingUsers,
    prominence,
  } = gameData;

  const { isLogin, isLoadingUser, updateList } = useUser();

  const { typeColor, typeLabel } = getGameTypeDisplay({
    isGameOver,
    isOutdoorGame,
    isScenarioGame,
    isTheaterGame,
    isBigGame,
    inShort: true
  })

  const handleLikeToggle = (e) => {
    e.preventDefault();

    trackEvent({
      category: `Like--${sourcePage}`,
      action: 'Click',
      label: `${gameId}-${title}`,
    });

    if (isLogin) {
      updateList('like', gameId)
    } else {
      if (!isLoadingUser) onOpenLogin();
    };
  };

  const handleEnterView = () => {
    trackEvent({
      category: `GameCard--${sourcePage}`,
      action: "Impression",
      label: `${gameId}-${title}`,
    })
  }

  const handleClick = () => {
    trackEvent({
      category: `GameCard--${sourcePage}`,
      action: "Click",
      label: `${gameId}-${title}`,
    });
  }

  return (
    <Waypoint onEnter={handleEnterView}>
      <Link 
        href={`/game/${gameId}`} 
        onClick={handleClick}
        className='w-fit'
      >
        <Card 
          flexDirection={{ base: 'column', sm: 'column' }}
          justifySelf='center'
          align='center' 
          w={{ base: '168px', sm: '300px' }}
          maxW="100%"
          _hover={{ transition: '200ms', shadow: 'lg' }}
        >
          <CardHeader p={0} w='100%'>
            <div className="relative w-full">
              <Tag
                size={{ base: 'sm', sm: 'md' }}
                variant='solid'
                colorScheme='secondary'
                pos='absolute'
                display={isPromotion ? 'inline-flex' : 'none'}
                top={{ base: 1, sm: 2 }}
                left={-1}
                zIndex={10}
                borderRadius={1}
              >
                <TagLabel>
                  {prominence || "本月推薦" }
                </TagLabel>
              </Tag>
              <LazyLoadImage
                src={mainImgBannerUrl || mainImgSquareUrl || GAME_CARD_IMAGE_PLACEHOLDER_SRC} 
                className="w-full object-cover h-[88px] max-h-[88px] sm:h-[157px] sm:max-h-[157px] rounded-tl-md rounded-tr-md" 
              />
              <div className='absolute bottom-0 left-0 w-full h-full flex pb-1 justify-between items-end bg-[linear-gradient(to_top,rgba(0,0,0,.8),rgba(0,0,0,.5)_15%,rgba(0,0,0,.2)_20%,rgba(0,0,0,0)_25%,transparent)]'>
                {features?.map((featureId, idx) => 
                  <div 
                    key={`${gameId}-${idx}`} 
                    className={clsx('px-2 sm:px-3 flex items-center', {'hidden sm:inline-flex' : idx >= 2})}
                  >
                    <Icon as={FaHashtag} boxSize={3} color='white' mr={1} />
                    <span key={`${featureId}-${idx}`} className='text-xs sm:text-sm text-white font-bold'>
                      {featureCodeMap[featureId]}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </CardHeader>

          <CardBody 
            w='100%'
            px={{ base: 1, sm: 3 }} 
            py={0}
          >
            <Heading
              as='h4'
              size={{ base: 'sm', sm: 'md' }}
              px={1}
              my={{ base: 1, sm: 2 }}
              noOfLines={1}
            >
              {title}
            </Heading>

            <Divider />

            <Stack
              direction='row'
              align='center'
              pt={2}
              pl={{ base: 1, sm: 0 }}
              gap={{ base: 1, sm: 2 }}
            >
              <Tag
                size={{ base: 'sm', sm: 'md' }}
                variant='outline' 
                colorScheme={typeColor}
              >
                <TagLabel>
                  {typeLabel}
                </TagLabel>
              </Tag>
              <Center>
                <Icon as={MdPlace} boxSize={{ base: 4, sm: 5 }} color="blue.600" mr={1} />
                <span className='text-xs sm:text-sm'>{allCityCodeMap[cityId]}</span>
              </Center>
              {firmLogoUrl && (
                <Avatar 
                  src={firmLogoUrl}
                  size={{ base: 'xs', sm: 'sm' }}
                  boxShadow='md'
                />
              )}
              {/* <IconButton
                isRound
                variant='ghost'
                colorScheme='blackAlpha'
                fontSize='20px'
                w={5}
                h={5}
                display={{ base: 'none', sm: 'inline-flex' }}
                icon={hasLike ? <MdFavorite /> : <MdFavoriteBorder />}
                isDisabled={isLoadingUser}
                onClick={handleLikeToggle}
              /> */}
            </Stack>

            <Stack
              direction='row'
              pl={{ base: 1, sm: 0 }}
              py={2}
            >
              <Center gap={1}>
                <Icon as={MdStar} boxSize={{ base: 4, sm: 5 }} color="yellow.300" />
                <span className='text-xs sm:text-sm font-medium'>
                  {(avgRating > 0 && totalRatingUsers > 0)
                    ? `${avgRating.toFixed(1)} (${totalRatingUsers.toLocaleString()} 人評價)` 
                    : '尚無評價' 
                  }
                </span>
              </Center>
            </Stack>

            <Stack
              direction='row'
              pl={{ base: 2, sm: 1 }}
              pb={2}
              gap={0}
            >
              {(minPeople && maxPeople) && (
                <Center>
                  <Icon as={MdPeople} boxSize={{ base: 4, sm: 5 }} color="blue.600" mr={1} />
                  <span className='text-xs sm:text-sm'>{minPeople}-{maxPeople} 人・</span>
                </Center>
              )}
              {(minPrice !== undefined && minPrice >= 0) && (
                <span className='text-xs sm:text-sm'>
                  {minPrice > 0 
                    ? `每人 $${parseInt(minPrice, 10).toLocaleString()} 起` 
                    : '免費'
                  }
                </span>
              )}
            </Stack>
          </CardBody>
        </Card>
      </Link>
    </Waypoint>
  )
};
